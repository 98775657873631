
import { getItemStatusTypeByStatus } from '@/helpers/ObjectHelper';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  /* Properties */
  @Prop({ required: true })
  status!: string;
  @Prop() isProof!: boolean;
  /* Store Actions */
  /* Watchers */
  @Watch('status')
  onStatusChange(val: any) {
    this.fileToLoad = getItemStatusTypeByStatus(this.status);
  }
  /* Data */
  fileToLoad: string = '';
  /* Utility Functions */
  cancelProof() {
    this.$emit('cancelProof');
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.fileToLoad = getItemStatusTypeByStatus(this.status);
  }
  /* Created */
}
