
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import LottieAnimation from 'lottie-web-vue';
@Component({
  components: {
    LottieAnimation
  }
})
export default class LottieIcon extends Vue {
  $refs!: { anim: HTMLFormElement };
  /* Properties */
  @Prop({ required: true }) animationDataPath!: string;
  @Prop() width!: number;
  @Prop() height!: number;
  @Prop({ default: false }) isContinuous!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  get iconHeight() {
    return this.height || '40';
  }
  get iconWidth() {
    return this.width || '40';
  }
  /* Async Functions */
  /* Utility Functions */
  moveIcon() {
    this.$refs.anim.pause();
    // this.$refs.anim.play();
  }
  resetIcon() {
    this.$refs.anim.play();
    // this.$refs.anim.stop();
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    let lottieIcons = document.querySelectorAll('.lottie');
    lottieIcons.forEach(l => l.children[0].classList.add('lottie'));
  }
  /* Created */
  /* Emmited Functions */
}
