
import { CustomerOrder, OrderlineDetails } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import SavedOrdersDialog from '@/components/SavedOrdersDialog.vue';
import J1lImg from '@/components/J1lImg.vue';
import { Confirm } from '@/helpers/AuthHelper';
import { OrdersService } from '@/services/orders-service';
@Component({
  components: {
    J1lImg,
    SavedOrdersDialog
  }
})
export default class extends Vue {
  $refs!: {
    SavedOrdersDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) drawer!: boolean;
  /* Store Actions */
  @Action('GetShoppingCart', { namespace: 'orderlines' })
  refreshShoppingCart!: () => Promise<any>;
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Getter('getShoppingCart', { namespace: 'orderlines' })
  shoppingCart!: CustomerOrder;
  @Action('RemoveFromShoppingCart', { namespace: 'orderlines' })
  removeFromCart: any;
  @Action('CalculatePriceOfShoppingCart', { namespace: 'orderlines' })
  CalculatePriceOfShoppingCart!: () => Promise<OrderlineDetails>;
  /* Watchers */
  @Watch('shouldShow')
  onShouldShowChange(val: boolean) {
    if (!val) this.$emit('drawerClosed');
  }
  @Watch('drawer')
  async onDrawerChange() {
    this.shouldShow = this.drawer;
    if (this.shouldShow && this.shoppingCart.Orderlines.some(o => o.ExtendedPrice == 0)) {
      this.isCalculatingPrice = true;
      await this.refreshShoppingCart();
      try {
        await this.CalculatePriceOfShoppingCart();
      } catch (err) {
        console.log('error calculating price');
        console.log(err);
      }
      this.isCalculatingPrice = false;
    }
    this.getSavedOrders();
  }
  /* Data */
  shouldShow: boolean = false;
  isCalculatingPrice: boolean = false;
  savedOrders: CustomerOrder[] = [];
  /* Utility Functions */
  openConfirmationDialog(orderlineId: string) {
    Confirm(
      () => {
        this.deleteOrderline(orderlineId);
      },
      'Remove Orderline',
      'Are you sure you want to remove this orderline?'
    );
  }
  async goToOrderView(po: string = '') {
    this.clearCurrentOrder();
    this.$router.push({
      name: 'OrderView',
      params: { po: this.shoppingCart.PO },
      query: { isCartOrder: 'true' }
    });
  }
  deleteOrderline(id: string) {
    this.removeFromCart({ orderlineId: id });
    this.refreshShoppingCart();
  }
  calculateShoppingCartTotal(orderlineList: OrderlineDetails[]) {
    return orderlineList
      .map(o => o.ExtendedPrice)
      .reduce((sum, current) => sum + current, 0)
      .toFixed(2);
  }
  openSavedOrdersDialog() {
    this.$refs.SavedOrdersDialog.openDialog();
    this.getSavedOrders();
  }
  /* Loaders */
  async getSavedOrders(po: string = 'po') {
    if (po) this.savedOrders.filter(o => o.PO !== po);
    const response = await OrdersService.GetSavedOrders();
    this.savedOrders = response.data.filter(d => !d.IsCartOrder);
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
