
import AuthService from '@/services/auth-service';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import BugReport from '@/components/BugReport.vue';
import { UserValidation } from '@/store/models/ProfileModel';
const namespace: string = 'profile';

@Component({
  components: { BasicHelpDialog, BugReport }
})
export default class LeftDrawer extends Vue {
  $refs!: {
    HelpDeskDialog: HTMLFormElement;
  };
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Getter('isTransparencyCustomer', { namespace: 'customer' })
  isTransparencyCustomer: boolean;
  @Prop({ default: false }) drawer!: boolean;
  @Prop() isDesktop!: boolean;
  @Prop() isTablet!: boolean;
  @Action('logout', { namespace }) logout: any;
  @Action('getUserValidation', { namespace: 'profile' })
  getUserValidation!: any;

  @Watch('userValidation', { deep: true })
  onUserValidationChange() {
    if (this.userValidation.isConfirmed) {
      this.items.map(i => (i.shouldShow = true));
      this.seconditems[0].shouldShow = true;
      this.seconditems[1].shouldShow = true;
    }
    if (this.userValidation.isAdmin) {
      this.seconditems[0].shouldShow = true;
      this.seconditems[this.seconditems.length - 1].shouldShow = true;
      this.seconditems[this.seconditems.length - 2].shouldShow = true;
    }
  }
  lastUpdated: string = `${process.env.VUE_APP_TIME}`;
  currentRoute: number = -1;
  items: Array<{
    icon: string;
    text: string;
    path: string;
    shouldShow: boolean;
    shouldRoute: boolean;
    shouldDisable: boolean;
    subtext: string;
    id: string;
  }> = [
    // {
    //   icon: 'dvr',
    //   text: 'Dashboard',
    //   path: '/',
    //   shouldShow: true,
    //   shouldRoute: true,
    //   shouldDisable: true,
    //   subtext: 'COMING SOON',
    //   id: 'Dashboard-link'
    // },
    {
      icon: 'wallpaper',
      text: 'Manage Items',
      path: '/items',
      shouldShow: false,
      shouldRoute: true,
      shouldDisable: false,
      subtext: '',
      id: 'ManageItems-link'
    },
    {
      icon: 'add_shopping_cart',
      text: 'Orders',
      path: '/orders',
      shouldShow: false,
      shouldRoute: true,
      shouldDisable: false,
      subtext: '',
      id: 'Orders-link'
    }
  ];
  seconditems: Array<{
    icon: string;
    text: string;
    path: string;
    shouldShow: boolean;
    shouldRoute: boolean;
  }> = [
    {
      icon: 'bar_chart',
      text: 'Quoter',
      path: '/quoter',
      shouldShow: false,
      shouldRoute: true
    },
    {
      icon: 'aspect_ratio',
      text: 'Templates',
      path: '/templates',
      shouldShow: false,
      shouldRoute: true
    },
    {
      icon: 'mdi-forum',
      text: 'Help',
      path: 'helpDialog',
      shouldShow: true,
      shouldRoute: false
    },
    // { icon: 'mdi-calendar-text', text: 'Address Book', path: '/addressbook' },
    {
      icon: 'person',
      text: 'Admin',
      path: '/admindashboard',
      shouldShow: false,
      shouldRoute: true
    },
    {
      icon: 'person',
      text: 'Impersonate',
      path: '/managecustomer',
      shouldShow: false,
      shouldRoute: true
    }
  ];
  goTo(path: string): void {
    if (path === 'helpDialog') {
      this.$refs.HelpDeskDialog.openDialog();
      return;
    }
    if (path === '/quoter' && this.isTransparencyCustomer) {
      path = '/TransparencyQuoter';
    }

    if (this.$route.path !== path) {
      if (path === '/') return;
      this.$router.push(path);
    } else {
      window.location.reload();
    }
  }
  logoutUser(): void {
    this.logout();
  }
  get drawerValue() {
    return this.drawer;
  }
  get width() {
    return this.isDesktop ? '285px' : this.isTablet ? '90px' : '285px';
  }
  set drawerValue(value) {
    this.$emit('update', value);
  }
  isRouteSelected(item: {
    icon: string;
    text: string;
    path: string;
    shouldShow: boolean;
    shouldRoute: boolean;
  }) {
    if (item) {
      let isRouteSelected =
        this.$router.currentRoute.path === item.path && item.shouldRoute;
      return isRouteSelected;
    }
  }
  mounted() {
    this.onUserValidationChange();
  }

  async created() {}
}
