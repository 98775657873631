
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import AdminService from '@/services/admin-service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = 'profile';
@Component
export default class ImpersonateCustomerDialog extends Vue {
  /* Properties */
  /* Store Actions */
  @Getter('impersonatedUser', { namespace })
  impersonatedUser!: string;
  @Getter('getCustomer', { namespace: 'customer' })
  customer: any;
  @Action('login', { namespace })
  login: any;
  @Action('getImpersonatedCustomer', { namespace })
  getImpersonatedCustomer: any;
  /* Watchers */
  /* Data */
  isLoadingCustomers: boolean = false;
  isImpersonating: boolean = false;
  dialog: boolean = false;
  customers: any[] = [];
  userName: string = '';
  search: string = '';
  scrolled: number = 0;
  /* Computed */
  get customersInSearch() {
    let numberToDisplay = 20 + 20 * this.scrolled;
    if (!this.search) {
      return this.customers;
    }
    return this.customers.filter(c =>
      c.Name.toLowerCase().includes(this.search.toLowerCase())
    );
  }
  /* Async Functions */
  async impersonate(customerId: string) {
    this.isImpersonating = true;
    const formData: any = new FormData();
    formData.set('userName', this.userName);
    formData.set('customerId', customerId);
    try {
      await this.login(formData);
      await this.getImpersonatedCustomer();
      this.isImpersonating = false;
      SetSnackBar('Impersonation successful');
      location.reload();
      return false;
    } catch (err) {
      SetSnackBar('Could not impersonate');
      this.isImpersonating = false;
    }
  }
  /* Utility Functions */
  openDialog() {
    const user = localStorage.getItem('user');
    if (user) {
      this.userName = JSON.parse(user).userName;
    }
    this.getAllCustomers();
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  edit(id: string) {
    this.$router.push(`/editcustomer/${id}`);
  }
  /* Loaders */
  async getAllCustomers() {
    this.isLoadingCustomers = true;
    const customers = await AdminService.getAllCustomers();
    customers.data.forEach((customer: any) => {
      this.customers.push({ ...customer, isVisible: true });
    });
    this.isLoadingCustomers = false;
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
