
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
@Component({
  components: { DefaultLayout }
})
export default class BillingAddressFormatter extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  @Prop() billingAddress!: any;
  @Prop({ default: 'start' }) justify!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
